export const UNLIMITED_WEI =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const NULL_APPROVAL_CONTRACTS = [
  '0x8A9C67fee641579dEbA04928c4BC45F66e26343A',
  '0x309627af60F0926daa6041B8279484312f2bf060',
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
  '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
  '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
  '0x1b22C32cD936cB97C28C5690a0695a82Abf688e6',
  '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
  '0x83cee9e086A77e492eE0bB93C2B0437aD6fdECCc',
  '0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a',
  '0x340D2bdE5Eb28c1eed91B2f790723E3B160613B7',
  '0x27f610BF36ecA0939093343ac28b1534a721DBB4',
  '0xF6B55acBBC49f4524Aa48D19281A9A77c54DE10f',
  '0x4CEdA7906a5Ed2179785Cd3A40A69ee8bc99C466',
  '0x4AaC461C86aBfA71e9d00d9a2cde8d74E4E1aeEa',
  '0xdd974D5C2e2928deA5F71b9825b8b646686BD200',
  '0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26',
  '0xA15C7Ebe1f07CaF6bFF097D8a589fb8AC49Ae5B3',
  '0xd341d1680Eeee3255b8C4c75bCCE7EB57f144dAe',
  '0xe3818504c1B32bF1557b16C238B2E01Fd3149C17',
  '0x3d1BA9be9f66B8ee101911bC36D3fB562eaC2244',
  '0x818Fc6C2Ec5986bc6E2CBf00939d90556aB12ce5',
  '0x780116D91E5592E58a3b3c76A351571b39abCEc6',
  '0xb056c38f6b7Dc4064367403E26424CD2c60655e1',
  '0x4162178B78D6985480A308B2190EE5517460406D',
  '0x0Cf0Ee63788A0849fE5297F3407f701E122cC023',
  '0xc20464e0C373486d2B3335576e83a218b1618A5E',
  '0xcbee6459728019CB1f2bB971dDe2eE3271BC7617',
  '0x6888a16eA9792c15A4DCF2f6C623D055c8eDe792',
  '0x744d70FDBE2Ba4CF95131626614a1763DF805B9E',
  '0x28dee01D53FED0Edf5f6E310BF8Ef9311513Ae40',
  '0x84F7c44B6Fed1080f647E354D552595be2Cc602F',
  '0xEF2463099360a085f1f10b076Ed72Ef625497a06',
  '0xf04a8ac553FceDB5BA99A64799155826C136b0Be',
  '0xF433089366899D83a9f26A773D59ec7eCF30355e',
  '0xa485bD50228440797Abb4d4595161d7546811160',
  '0x9214eC02CB71CbA0ADA6896b8dA260736a67ab10',
  '0x960b236A07cf122663c4303350609A66A7B288C0',
  '0x47Ec6AF8E27C98e41d1Df7fb8219408541463022',
  '0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6',
  '0x6c37Bf4f042712C978A73e3fd56D1F5738dD7C43',
  '0x0cB20b77AdBe5cD58fCeCc4F4069D04b327862e5',
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  '0xB8BAa0e4287890a5F79863aB62b7F175ceCbD433',
  '0x0000000000085d4780B73119b644AE5ecd22b376',
  '0x26E75307Fc0C021472fEb8F727839531F112f317',
  '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
  '0xC011A72400E58ecD99Ee497CF89E3775d4bd732F',
  '0xb1A5b7e9a268742B9B5D2455fFcf43BaBC6929bA',
  '0x5e6F3BC1186132565946feA123181529e7AeAFD8',
  '0xd758b77BCC792AFD58857E1d5C610aE649FDEE6b',
  '0x57Ab1E02fEE23774580C119740129eAC7081e9D3',
  '0x0316EB71485b0Ab14103307bf65a021042c6d380',
  '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
  '0x67Abf1C62D8Acd07aDa35908d38Cd67bE7DfEB36',
  '0xEE4dC4C5Ca843B83035d8E5159AC1bd1b4EbdfF5',
  '0x94A2aAA374A8F2D52dad24330C8a0Ec2934700ae',
  '0x7db5454F3500f28171d1f9c7a38527C9cF94e6b2',
  '0xb5f278Ee11811eFEC0692EC61b1e9f9984f2de11',
  '0x0eCDd783dc7bF820614044B51862ED29714d2BA5',
  '0x37Be876EF051eB8EDdD0745107c5222D8CA8EC60',
  '0x83Ee8ec605C0aE3D7F1c9e360aB45A6C1C033Ab9',
  '0xE7845A9679dAd2B1cCCe49d5F0239d1c528f7A40',
  '0x623FA86c0e010Fe4701cEDF294C9cddb8f4F26e6',
  '0x8Ae56a6850a7cbeaC3c3Ab2cB311e7620167eAC8',
  '0x7cE0641D19095ed3226fC5222836901bcE41585D',
  '0xCB0aD5f479812edD6e2cED1cfE621bF39D7E9158',
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
  '0x79A91cCaaa6069A571f0a3FA6eD257796Ddd0eB4',
  '0x107721d9aA07d9DE8f2CC9545e0C9346A9Bb503b',
  '0x4a527d8fc13C5203AB24BA0944F4Cb14658D1Db6',
  '0xa1d65E8fB6e87b60FECCBc582F7f97804B725521',
  '0x0Ae055097C6d159879521C384F1D2123D1f195e6',
  '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
  '0x0B244e01B1B0C9a959b3b0Bc19E3852395319876',
  '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
  '0xF35cCfbcE1228014F66809EDaFCDB836BFE388f5',
  '0x48Fb253446873234F2fEBbF9BdeAA72d9d387f94',
  '0x15b0dD2c5Db529Ab870915ff498bEa6d20Fb6b96',
].map((address) => address.toLowerCase());
