import { TokenList } from 'libs/tokens';
import { tokenDragonswapListParser, tokenSeiListParser } from './sei/utils';
import { lsService } from 'services/localeStorage';
import { AppConfigSchema } from './configSchema';
import * as v from 'valibot';
import { AppConfig } from './types';

export const pairsToExchangeMapping: { [key: string]: string } = {
  ETHUSDT: 'BINANCE:ETHUSDT',
  ETHDAI: 'UNISWAP3ETH:WETHDAI',
  ETHUSDC: 'UNISWAP3ETH:WETHUSDC_88E6A0.USD',
  ETHBUSD: 'BINANCE:ETHBUSD',
  ETHGUSD: 'GEMINI:ETHGUSD',
  ETHWBTC: 'BINANCE:ETHBTC',
  ETHSTETH: 'UNISWAP3ETH:WETHSTETH',
  ETHRETH: 'UNISWAP3ETH:WETHRETH',
  ETHFRXETH: 'UNISWAP3ETH:WETHFRXETH',
  ETHFRAX: 'UNISWAP3ETH:WETHFRAX',
  WETHUSDT: 'BINANCE:ETHUSDT',
  WETHDAI: 'UNISWAP3ETH:WETHDAI',
  WETHUSDC: 'UNISWAP3ETH:WETHUSDC_88E6A0.USD',
  WETHBUSD: 'BINANCE:ETHBUSD',
  WETHGUSD: 'GEMINI:ETHGUSD',
  WETHWBTC: 'BINANCE:ETHBTC',
  WETHSTETH: 'UNISWAP3ETH:WETHSTETH',
  WETHRETH: 'UNISWAP3ETH:WETHRETH',
  WETHFRXETH: 'UNISWAP3ETH:WETHFRXETH',
  USDTDAI: 'BINANCE:USDTDAI',
  USDTUSDC: 'COINBASE:USDTUSDC',
  USDTBUSD: 'UNISWAP3ETH:USDTBUSD',
  USDTGUSD: 'UNISWAP3ETH:USDTGUSD',
  USDTWBTC: 'UNISWAP3ETH:USDTWBTC',
  USDTETH: 'UNISWAP3ETH:USDTETH',
  USDTWETH: 'UNISWAP3ETH:USDTETH',
  BNBUSDT: 'BINANCE:BNBUSDT',
  BNBDAI: 'BINANCE:BNBUSDT',
  BNBUSDC: 'OKX:BNBUSDC',
  BNBBUSD: 'BINANCE:BNBBUSD',
  BNBWBTC: 'BINANCE:BNBBTC',
  BNBETH: 'BINANCE:BNBETH',
  BNBWETH: 'BINANCE:BNBETH',
  USDCUSDT: 'OKX:USDCUSDT',
  USDCDAI: 'UNISWAP3ETH:USDCDAI',
  USDCGUSD: 'UNISWAP3ETH:USDCGUSD',
  USDCLUSD: 'UNISWAP3ETH:USDCLUSD',
  USDCFRAX: 'UNISWAP3ETH:USDCFRAX',
  USDCMIM: 'UNISWAP3ETH:USDCMIM',
  USDCTUSD: 'UNISWAP3ETH:USDCTUSD',
  USDCUSDD: 'UNISWAP3ETH:USDCUSDD',
  USDCBUSD: 'UNISWAP3ETH:USDCBUSD',
  USDCUSDP: 'UNISWAP3ETH:USDCUSDP',
  USDCWBTC: 'UNISWAP3ETH:USDCWBTC',
  USDCETH: 'UNISWAP3ETH:USDCWETH',
  USDCWETH: 'UNISWAP3ETH:USDCWETH',
  MATICUSDT: 'BINANCE:MATICUSDT',
  MATICDAI: 'UNISWAP3ETH:MATICDAI',
  MATICUSDC: 'OKX:MATICUSDC',
  MATICBUSD: 'BINANCE:MATICBUSD',
  MATICWBTC: 'BINANCE:MATICBTC',
  MATICETH: 'BINANCE:MATICETH',
  MATICWETH: 'BINANCE:MATICETH',
  BUSDUSDT: 'UNISWAP3ETH:BUSDUSDT',
  BUSDDAI: 'UNISWAP3ETH:BUSDDAI',
  BUSDUSDC: 'UNISWAP3ETH:BUSDUSDC',
  BUSDETH: 'UNISWAP3ETH:BUSDWETH',
  BUSDWETH: 'UNISWAP3ETH:BUSDWETH',
  OKBUSDT: 'OKX:OKBUSDT',
  OKBDAI: 'OKX:OKBUSDT',
  OKBUSDC: 'OKX:OKBUSDC',
  OKBBUSD: 'OKX:OKBUSDT',
  OKBWBTC: 'OKX:OKBBTC',
  OKBETH: 'OKX:OKBETH',
  OKBWETH: 'OKX:OKBETH',
  STETHUSDT: 'MEXC:STETHUSDT',
  STETHDAI: 'UNISWAP3ETH:STETHUSDC',
  STETHUSDC: 'UNISWAP3ETH:STETHUSDC',
  STETHBUSD: 'UNISWAP3ETH:STETHUSDC',
  STETHETH: 'UNISWAP3ETH:STETHUSDC',
  STETHWETH: 'UNISWAP3ETH:STETHUSDC',
  SHIBUSDT: 'BINANCE:SHIBUSDT',
  SHIBDAI: 'COINBASE:SHIBUSD',
  SHIBUSDC: 'UNISWAP3ETH:SHIBUSDC',
  SHIBBUSD: 'BINANCE:SHIBBUSD',
  SHIBWBTC: 'OKX:SHIBBTC',
  SHIBETH: 'UNISWAP3ETH:SHIBWETH',
  SHIBWETH: 'UNISWAP3ETH:SHIBWETH',
  UNIUSDT: 'UNISWAP3ETH:UNIUSDT',
  UNIDAI: 'UNISWAP3ETH:UNIDAI',
  UNIUSDC: 'UNISWAP3ETH:UNIUSDC',
  UNIBUSD: 'BINANCE:UNIBUSD',
  UNIWBTC: 'UNISWAP3ETH:UNIWBTC',
  UNIETH: 'UNISWAP3ETH:UNIWETH',
  UNIWETH: 'UNISWAP3ETH:UNIWETH',
  UNILINK: 'UNISWAP:UNILINK',
  DAIUSDT: 'OKX:USDCUSDT',
  DAIGUSD: 'UNISWAP3ETH:DAIGUSD',
  DAILUSD: 'UNISWAP3ETH:USDCLUSD',
  DAIFRAX: 'UNISWAP3ETH:DAIFRAX',
  DAIBUSD: 'UNISWAP3ETH:DAIBUSD',
  DAIWBTC: 'UNISWAP3ETH:DAIWBTC',
  DAIETH: 'UNISWAP3ETH:DAIWETH',
  DAIWETH: 'UNISWAP3ETH:DAIWETH',
  WBTCUSDT: 'UNISWAP3ETH:WBTCUSDT',
  WBTCDAI: 'UNISWAP3ETH:WBTCDAI',
  WBTCUSDC: 'UNISWAP3ETH:WBTCUSDC',
  WBTCBUSD: 'BINANCE:WBTCBUSD',
  WBTCETH: 'UNISWAP3ETH:WBTCWETH',
  WBTCWETH: 'UNISWAP3ETH:WBTCWETH',
  WBTCYFI: 'UNISWAP3ETH:WBTCYFI',
  WBTCSNX: 'UNISWAP3ETH:WBTCSNX',
  WBTCUNI: 'UNISWAP3ETH:WBTCUNI',
  WBTCAAVE: 'UNISWAP3ETH:WBTCAAVE',
  WBTCHBTC: 'UNISWAP3ETH:WBTCHBTC',
  WBTCLINK: 'UNISWAP3ETH:WBTCLINK',
  WBTCLQTY: 'UNISWAP3ETH:WBTCLQTY',
  WBTCLUNA: 'UNISWAP3ETH:WBTCLUNA',
  WBTCWDOGE: 'UNISWAP3ETH:WBTCWDOGE',
  WBTCBADGER: 'UNISWAP3ETH:WBTCBADGER',
  WBTC1INCH: 'UNISWAP3ETH:WBTC1INCH',
  LINKUSDT: 'UNISWAP3ETH:LINKUSDT',
  LINKDAI: 'UNISWAP3ETH:LINKDAI',
  LINKUSDC: 'UNISWAP3ETH:LINKUSDC',
  LINKBUSD: 'BINANCE:LINKBUSD',
  LINKWBTC: 'UNISWAP3ETH:LINKWBTC',
  LINKETH: 'UNISWAP3ETH:LINKWETH',
  LINKWETH: 'UNISWAP3ETH:LINKWETH',
  TONUSDT: 'OKX:TONUSDT',
  TONUSDC: 'OKX:TONUSDC',
  TONWBTC: 'COINEX:TONBTC',
  TONETH: 'UNISWAP:TONWETH',
  TONWETH: 'UNISWAP:TONWETH',
  LEOUSDT: 'BITFINEX:LEOUSD',
  LEODAI: 'BITFINEX:LEOUSD',
  LEOUSDC: 'BITFINEX:LEOUSD',
  LEOBUSD: 'BITFINEX:LEOUSD',
  LEOWBTC: 'BITFINEX:LEOBTC',
  LEOETH: 'BITFINEX:LEOETH',
  LEOWETH: 'BITFINEX:LEOETH',
  LDOUSDT: 'UNISWAP3ETH:LDOUSDT',
  LDODAI: 'UNISWAP3ETH:LDODAI',
  LDOUSDC: 'UNISWAP3ETH:LDOUSDC',
  LDOBUSD: 'BINANCE:LDOBUSD',
  LDOWBTC: 'BINANCE:LDOBTC',
  LDOETH: 'UNISWAP3ETH:LDOWETH',
  LDOWETH: 'UNISWAP3ETH:LDOWETH',
  CROUSDT: 'OKX:CROUSDT',
  CRODAI: 'UNISWAP3ETH:CRODAI',
  CROUSDC: 'OKX:CROUSDT',
  CROBUSD: 'OKX:CROUSDT',
  CROWBTC: 'OKX:CROBTC',
  CROETH: 'UNISWAP3ETH:CROWETH',
  CROWETH: 'UNISWAP3ETH:CROWETH',
  APEUSDT: 'UNISWAP3ETH:APEUSDT',
  APEDAI: 'UNISWAP3ETH:APEUSDT',
  APEUSDC: 'UNISWAP3ETH:APEUSDC',
  APEBUSD: 'BINANCE:APEBUSD',
  APEWBTC: 'OKX:APEBTC',
  APEETH: 'UNISWAP3ETH:APEWETH',
  APEWETH: 'UNISWAP3ETH:APEWETH',
  QNTUSDT: 'BINANCE:QNTUSDT',
  QNTDAI: 'BINANCE:QNTUSDT',
  QNTUSDC: 'UNISWAP3ETH:QNTUSDC',
  QNTBUSD: 'BINANCE:QNTBUSD',
  QNTWBTC: 'BINANCE:QNTBTC',
  QNTETH: 'UNISWAP3ETH:QNTWETH',
  QNTWETH: 'UNISWAP3ETH:QNTWETH',
  QNTBNB: 'BINANCE:QNTBNB',
  QNTMATIC: 'UNISWAP3ETH:QNTMATIC',
  GRTUSDT: 'BINANCE:GRTUSDT',
  GRTDAI: 'UNISWAP3ETH:GRTUSDC',
  GRTUSDC: 'UNISWAP3ETH:GRTUSDC',
  GRTBUSD: 'BINANCE:GRTBUSD',
  GRTWBTC: 'BINANCE:GRTBTC',
  GRTETH: 'UNISWAP3ETH:GRTWETH',
  GRTWETH: 'UNISWAP3ETH:GRTWETH',
  FTMUSDT: 'BINANCE:FTMUSDT',
  FTMDAI: 'UNISWAP3ETH:FTMDAI',
  FTMUSDC: 'UNISWAP3ETH:FTMUSDC',
  FTMBUSD: 'BINANCE:FTMBUSD',
  FTMWBTC: 'BINANCE:FTMBTC',
  FTMETH: 'UNISWAP3ETH:FTMWETH',
  FTMWETH: 'UNISWAP3ETH:FTMWETH',
  SANDUSDT: 'BINANCE:SANDUSDT',
  SANDDAI: 'BINANCE:SANDUSDT',
  SANDUSDC: 'UNISWAP3ETH:SANDUSDC',
  SANDBUSD: 'BINANCE:SANDBUSD',
  SANDWBTC: 'BINANCE:SANDBTC',
  SANDETH: 'UNISWAP3ETH:SANDWETH',
  SANDWETH: 'UNISWAP3ETH:SANDWETH',
  MANAUSDT: 'BINANCE:MANAUSDT',
  MANADAI: 'SUSHISWAP:MANADAI',
  MANAUSDC: 'UNISWAP3ETH:MANAUSDC',
  MANABUSD: 'BINANCE:MANABUSD',
  MANAWBTC: 'BINANCE:MANABTC',
  MANAETH: 'UNISWAP3ETH:MANAWETH',
  MANAWETH: 'UNISWAP3ETH:MANAWETH',
  AAVEUSDT: 'UNISWAP3ETH:AAVEUSDT',
  AAVEDAI: 'UNISWAP3ETH:AAVEUSDT',
  AAVEUSDC: 'UNISWAP3ETH:AAVEUSDC',
  AAVEBUSD: 'BINANCE:AAVEBUSD',
  AAVEWBTC: 'UNISWAP3ETH:AAVEWBTC',
  AAVEETH: 'UNISWAP3ETH:AAVEWETH',
  AAVEWETH: 'UNISWAP3ETH:AAVEWETH',
  AXSUSDT: 'BINANCE:AXSUSDT',
  AXSDAI: 'BINANCE:AXSUSDT',
  AXSUSDC: 'OKX:AXSUSDC',
  AXSBUSD: 'BINANCE:AXSBUSD',
  AXSWBTC: 'BINANCE:AXSBTC',
  AXSETH: 'UNISWAP3ETH:AXSWETH',
  AXSWETH: 'UNISWAP3ETH:AXSWETH',
  AXSBNB: 'BINANCE:AXSBNB',
  FRAXUSDT: 'UNISWAP3ETH:FRAXUSDT',
  FRAXDAI: 'UNISWAP3ETH:FRAXDAI',
  FRAXUSDC: 'UNISWAP3ETH:FRAXUSDC',
  FRAXWETH: 'UNISWAP3ETH:FRAXWETH',
  FRAXETH: 'UNISWAP3ETH:FRAXWETH',
  FRAXSUSD: 'UNISWAP3ETH:FRAXSUSD',
  TUSDUSDT: 'UNISWAP3ETH:TUSDUSDT',
  TUSDDAI: 'UNISWAP3ETH:TUSDUSDT',
  TUSDUSDC: 'UNISWAP3ETH:TUSDUSDC',
  TUSDBUSD: 'UNISWAP3ETH:TUSDUSDC',
  TUSDWBTC: 'UPBIT:TUSDBTC',
  TUSDETH: 'UNISWAP3ETH:TUSDWETH',
  TUSDWETH: 'UNISWAP3ETH:TUSDWETH',
  TUSDGOLD: 'UNISWAP3ETH:TUSDTGOLD',
  HTUSDT: 'KUCOIN:HTUSDT',
  HTDAI: 'KUCOIN:HTUSDT',
  HTUSDC: 'KUCOIN:HTUSDT',
  HTBUSD: 'KUCOIN:HTUSDT',
  HTWBTC: 'COINEX:HTBTC',
  HTETH: 'UNISWAP:HTWETH_26CE49',
  HTWETH: 'UNISWAP:HTWETH_26CE49',
  RPLUSDT: 'BINANCE:RPLUSDT',
  RPLDAI: 'BINANCE:RPLUSDT',
  RPLUSDC: 'OKX:RPLUSDC',
  RPLBUSD: 'BINANCE:RPLBUSD',
  RPLWBTC: 'BINANCE:RPLBTC',
  RPLETH: 'UNISWAP3ETH:RPLWETH',
  RPLWETH: 'UNISWAP3ETH:RPLWETH',
  CRVUSDT: 'BINANCE:CRVUSDT',
  CRVDAI: 'BINANCE:CRVUSDT',
  CRVUSDC: 'UNISWAP3ETH:CRVUSDC',
  CRVBUSD: 'BINANCE:CRVBUSD',
  CRVWBTC: 'COINBASE:CRVBTC',
  CRVETH: 'UNISWAP3ETH:CRVWETH',
  CRVWETH: 'UNISWAP3ETH:CRVWETH',
  SNXUSDT: 'BINANCE:SNXUSDT',
  SNXDAI: 'BINANCE:SNXUSDT',
  SNXUSDC: 'UNISWAP3ETH:SNXUSDC',
  SNXBUSD: 'BINANCE:SNXBUSD',
  SNXWBTC: 'UNISWAP3ETH:SNXWBTC',
  SNXETH: 'UNISWAP3ETH:SNXWETH',
  SNXWETH: 'UNISWAP3ETH:SNXWETH',
  BNTUSDT: 'BINANCE:BNTUSDT',
  BNTDAI: 'BINANCE:BNTUSDT',
  BNTUSDC: 'BINANCE:BNTUSDT',
  BNTWBTC: 'COINBASE:BNTBTC',
  BNTETH: 'BINANCE:BNTETH',
  BNTWETH: 'BINANCE:BNTETH',
  COTETH: 'UNISWAP3ETH:',
  COTWETH: 'UNISWAP3ETH:',
  SEIUSDC: 'BINANCE:SEIUSDC',
  SEIUSDT: 'BINANCE:SEIUSDT',
  SEIWBTC: 'BINANCE:SEIBTC',
  SEIBNB: 'BINANCE:SEIBNB',
  SEITUSD: 'BINANCE:SEITUSD',
};

export const tokenParserMapKeys = [
  'tokenDragonswapListParser',
  'tokenSeiListParser',
] as const;

export const tokenParserMap: Record<
  string,
  (data: any) => Promise<TokenList> | TokenList
> = {
  tokenDragonswapListParser: tokenDragonswapListParser(
    'dragonswap-app/assets',
    'logos'
  ),
  tokenSeiListParser: tokenSeiListParser('pacific-1'),
};

export const handleConfigOverrides = (defaultConfig: AppConfig) => {
  const configOverride = lsService.getItem('configOverride');

  const currentConfig = { ...defaultConfig, ...configOverride };
  const parseResult = v.safeParse(AppConfigSchema, currentConfig);

  if (parseResult.issues) {
    console.log("Couldn't load config, clearing config overrides...");
    lsService.removeItem('configOverride');
    return defaultConfig;
  }
  console.log('Config loaded successfully');
  return parseResult.output as AppConfig;
};
